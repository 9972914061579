export const HASURA_NAMES = {
  org_3R60rCG9HWYwPpC0: 'dev-folio-db',
  org_uajc8Wr0rLFlqXz8: 'uat',
  org_kUN3WbpsHzp0hbX6: 'artis-staging',
  org_XYwWBaz6vrPdxjeW: 'prod-folio-tpicap',
} as const;

export function getHasuraName(orgId: keyof typeof HASURA_NAMES) {
  const name = HASURA_NAMES[orgId];
  if (name) {
    return name;
  } else {
    throw new Error(`No Hasura name found for Org ID: ${orgId}`);
  }
}

export const isDev = import.meta.env.DEV;
