import { MiniDb } from 'jotai-minidb';
import { JWT, checkToken } from './Auth';
import { atom, getDefaultStore, useAtomValue, useSetAtom } from 'jotai';
import { jwtDecode } from 'jwt-decode';
import logger from './logger';
import z, { ZodSchema } from 'zod';
import { getHasuraName, isDev } from './constants';
import localforage from 'localforage';
import { DevTools } from 'jotai-devtools';

export const pageSchema = z.object({
  id: z.string(),
  name: z.string(),
  index: z.optional(z.number()),
  pageProducts: z.array(
    z
      .object({
        id: z.string(),
        'grid-id': z.string(),
        'grid-index': z.optional(z.number()),
      })
      .nullable(),
  ),
});

export const packageSchema = z.object({
  id: z.number(),
  name: z.string(),
  source: z.object({
    name: z.string(),
    exchange: z.nullable(z.object({ code: z.number() })),
  }),
  products: z.array(
    z.object({
      id: z.string(),
      artis_type: z.string(),
      code: z.nullable(z.string()),
      description: z.string(),
      eod_product_dep: z.nullable(z.string()),
      logical_code: z.nullable(z.string()),
      maturity: z.string(),
      name: z.string(),
      summary_type: z.object({
        value: z.union([
          z.literal('sum'),
          z.literal('average'),
          z.literal('timespread'),
          z.literal('none'),
        ]),
      }),
      product_type: z.union([
        z.literal('box'),
        z.literal('change'),
        z.literal('diff'),
        z.literal('outright'),
        z.literal('timespread'),
      ]),
      uom: z.union([
        z.literal('usd_bbl'),
        z.literal('ws_and_usd_ton'),
        z.literal('usd_ton'),
        z.literal('usc_gal'),
        z.literal('usd_gal'),
        z.literal('percent'),
        z.literal('kt'),
        z.literal('kb'),
        z.literal('kbd'),
        z.literal('kgal'),
        z.literal('kcbm'),
        z.literal('ws'),
        z.literal('usd_thousands'),
        z.literal('flatr'),
        z.literal('kg_m3'),
        z.literal('days'),
        z.literal('none'),
        z.literal('usd_day'),
        z.literal('lots'),
        z.literal('usc_bbl'),
        z.literal('p_thm'),
        z.literal('usd_mmbtu'),
        z.literal('usdmm'),
        z.literal('eur_mwh'),
        z.literal('gbp'),
        z.literal('eur'),
        z.literal('usd'),
        z.literal('gbp_mt'),
        z.literal('eur_mt'),
      ]),
      has_shared_cell: z.boolean(),
      geographical_region: z.nullable(z.object({ id: z.number() })),
      commodity_group: z.nullable(z.object({ id: z.number() })),
      commodity_parent_group: z.nullable(
        z.object({ parent: z.object({ id: z.number() }) }),
      ),
      product_configs: z.array(
        z.object({
          formula: z.nullable(z.string()),
          product: z.string(),
          relative_month: z.number(),
        }),
      ),
    }),
  ),
});

export type TPackage = z.infer<typeof packageSchema>;
export type TProduct = TPackage['products'][number];

export type TPage = z.infer<typeof pageSchema>;

export const pageStore = new MiniDb<TPage>({
  name: 'artis-page-db',
  version: 0,
});

export const configSchema = z.object({
  selectedPageIds: z.array(z.string()),
  openWorkSheets: z.array(z.string()),
  packages: z.array(packageSchema),
  token: z.string(),
  lastUpdated: z.nullable(z.number()),
});

export type TConfig = z.infer<typeof configSchema>;
const defaultConfig = {
  selectedPageIds: [],
  openWorkSheets: [],
  packages: [],
  token: '',
  lastUpdated: null,
};

export const configStore = new MiniDb<TConfig>({
  name: 'artis-config-db',
  version: 0,
  initialData: {
    config: defaultConfig,
  },
});

export function logoutFn() {
  const store = getDefaultStore();
  store.set(configStore.set, 'config', (old) => {
    return {
      ...defaultConfig,
      selectedPageIds: old?.selectedPageIds ?? [],
      lastUpdated: old?.lastUpdated ?? null,
    };
  });
  store.set(pageStore.clear);
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

function validate<T>(zodSchema: ZodSchema<T>, item: T) {
  if (isDev) {
    return item;
  } else {
    return zodSchema.parse(item);
  }
}

export function usePages() {
  const currentPages = useAtomValue(pageStore.values).sort(
    (a, b) => (a?.index ?? 0) - (b?.index ?? 0),
  );

  const setPages = useSetAtom(pageStore.setMany);
  const clearPages = useSetAtom(pageStore.clear);
  const setPagesFn = (pages: TPage[]) => {
    clearPages();
    const valid_pages = validate(z.array(pageSchema), pages);
    const newPages: [string, TPage][] = valid_pages.map((page, i) => {
      return [page.id, { ...page, index: i }];
    });
    setPages(newPages);
  };
  return [currentPages, setPagesFn] as const;
}

export const selectedPageIdsAtom = atom((get) => {
  const config = get(configStore.item('config'));
  return config?.selectedPageIds ?? [];
});
selectedPageIdsAtom.debugLabel = 'selectedPageIdsAtom';

export function useSelectedPageIds() {
  const selectedPages = useAtomValue(selectedPageIdsAtom);
  const setPages = useSetAtom(configStore.set);
  const setPagesFn = (pages: TPage[]) => {
    const valid_pages = validate(z.array(pageSchema), pages);
    const pageIds = valid_pages.map((page) => page.id);
    logger.debug('setPagesFn', { pageIds });
    setPages('config', (prev) => {
      return {
        ...(prev ?? defaultConfig),
        selectedPageIds: pageIds,
      };
    });
  };
  return [selectedPages, setPagesFn] as const;
}

export function useSelectedPages() {
  const [pageIds, setPages] = useSelectedPageIds();
  const [pages] = usePages();
  const selectedPages = pages.filter((page) => pageIds.includes(page.id));
  return [selectedPages, setPages] as const;
}

const tokenAtom = atom((get) => {
  const config = get(configStore.item('config'));
  return config?.token;
});

export function useToken() {
  const token = useAtomValue(tokenAtom);
  const setToken = useSetAtom(configStore.set);
  const setTokenFn = (token: string) => {
    setToken('config', (prev) => {
      return {
        ...(prev ?? defaultConfig),
        token,
      };
    });
  };
  return [token, setTokenFn] as const;
}

export const decodedToken = atom((get) => {
  const token = get(tokenAtom);
  if (!token) return undefined;
  try {
    const decoded = jwtDecode(token) as JWT;
    const checks = checkToken({ decoded });
    if (checks?.error) {
      logger.error('useDecodedToken error', { checks });
      return {
        error: checks.error,
      };
    }
    if (checks?.tokenMsToExpiry) {
      localforage.setItem('tokenExpMs', checks.tokenMsToExpiry);
      return {
        decoded,
        error: checks.error,
        rawToken: token,
        expiresInMs: checks.tokenMsToExpiry,
      };
    } else {
      logger.warn('token expired', { decoded, checks });
    }
  } catch (error) {
    logger.error('useDecodedToken error', { error });
    return undefined;
  }
});

export const user = atom((get) => {
  const decoded = get(decodedToken);
  return {
    id: decoded?.decoded?.sub,
    error: decoded?.error,
    expiresInMs: decoded?.expiresInMs,
    rawToken: decoded?.rawToken,
    email:
      decoded?.decoded?.['https://hasura.io/jwt/claims']['x-hasura-user-email'],
  };
});

export function useUser() {
  const userAtom = useAtomValue(user) ?? {
    expiresInMs: 0,
    id: '',
    rawToken: '',
    error: null,
    email: '',
  };
  return userAtom;
}

export function useDecodedToken() {
  return useAtomValue(decodedToken);
}

export const lastUpdatedAtom = atom((get) => {
  const config = get(configStore.item('config'));
  const lastUpdatedMs = config?.lastUpdated;
  if (!lastUpdatedMs) return undefined;
  return new Date(lastUpdatedMs);
});
lastUpdatedAtom.debugLabel = 'lastUpdatedAtom';

export function useLastUpdated() {
  const value = useAtomValue(lastUpdatedAtom);
  const setConfig = useSetAtom(configStore.set);
  const setLastUpdatedFn = (lastUpdated: number) => {
    setConfig('config', (prev) => {
      return {
        ...(prev ?? defaultConfig),
        lastUpdated,
      };
    });
  };
  return [value, setLastUpdatedFn] as const;
}

const hasuraName = atom((get) => {
  const decoded = get(decodedToken);
  if (!decoded) {
    logger.warn('No decoded token found');
    return undefined;
  }
  if (decoded.error) {
    logger.error('decoded token error', { error: decoded.error });
    return 'uat';
  }
  const org_id = decoded.decoded?.org_id;
  if (!org_id) {
    logger.error('No org_id found in decoded token');
    return undefined;
  }
  return getHasuraName(org_id);
});
hasuraName.debugLabel = 'hasuraNameAtom';

export function useHasuraName() {
  return useAtomValue(hasuraName);
}

export function useExcelSheets() {
  const sheets = useAtomValue(configStore.item('config'))?.openWorkSheets;
  const setSheets = useSetAtom(configStore.set);
  const setSheetsFn = (sheets: string[]) => {
    const newSheets = validate(z.array(z.string()), sheets);
    setSheets('config', (prev) => {
      if (!prev) {
        return defaultConfig;
      }
      return {
        ...prev,
        openWorkSheets: newSheets,
      };
    });
  };
  return [sheets ?? [], setSheetsFn] as const;
}

const flattenPackagesToProducts = (packages: TPackage[]) => {
  return packages
    .map((pack) => {
      const source = pack.source.name;
      const products = pack.products.map((prod) => {
        return {
          ...prod,
          source,
        };
      });
      return products;
    })
    .flat();
};

export const productsAtom = atom((get) => {
  const config = get(configStore.item('config'));
  const packages = config?.packages ?? [];
  const products = flattenPackagesToProducts(packages);
  return products;
});
productsAtom.debugLabel = 'productsAtom';

export function useProducts() {
  return useAtomValue(productsAtom);
}

export function useSetPackages() {
  const setPackages = useSetAtom(configStore.set);
  const setPackagesFn = (packages: TPackage[]) => {
    const valid_packages = validate(z.array(packageSchema), packages);
    setPackages('config', (prev) => {
      return {
        ...(prev ?? defaultConfig),
        packages: valid_packages,
      };
    });
  };
  return setPackagesFn;
}

export const readyAtom = atom(false);

export function useReady() {
  return useAtomValue(readyAtom);
}

export function StoreProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <DevTools isInitialOpen={false} />
      {children}
    </>
  );
}
