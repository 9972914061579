import logger from "./logger";
import { Combobox, Transition } from "@headlessui/react";
import { PWBHost } from "promise-worker-bi";
import { useEffect, useState, Fragment, useRef, useCallback } from "react";
import clsx from "clsx";
import { Button, LogoutButton } from "./Buttons";
import { useAuth } from "./Auth";
import {
  TPackage,
  TPage,
  lastUpdatedAtom,
  logoutFn,
  pageStore,
  productsAtom,
  readyAtom,
  selectedPageIdsAtom,
  useExcelSheets,
  useHasuraName,
  useLastUpdated,
  usePages,
  useReady,
  useSelectedPageIds,
  useSelectedPages,
  useSetPackages,
} from "./store";
import { getDefaultStore, useAtomValue } from "jotai";
import localforage from "localforage";
import { useNetworkState } from "@uidotdev/usehooks";
import { useHotkeys } from "react-hotkeys-hook";

function compareStr(s1: string, s2: string) {
  return s1.toLowerCase().trim() === s2.toLowerCase().trim();
}

function uomToLabel(uom: string | null | undefined): string | null {
  switch (uom) {
    case "usd_bbl":
      return "$/bbl";
    case "usc_bbl":
      return "c/bbl";
    case "usd_ton":
      return "$/ton";
    case "usd_gal":
      return "$/gal";
    case "usc_gal":
      return "c/gal";
    case "percent":
      return "%";
    case "usd_thousands":
      return "$'000s";
    case "kg_m3":
      return "kg/m³";
    case "days":
      return "days";
    case "usd_day":
      return "$/day";
    case "usd_mmbtu":
      return "$/mmbtu";
    case "usdmm":
      return "$mm";
    case "p_thm":
      return "p/thm";
    case "ws_and_usd_ton":
      return "ws & $/ton";
    case "none":
      return "-";
    default:
      return uom ? uom.replace(/000S/g, "000s") : null;
  }
}

type TPeriodCount = Array<[number, string]>;
type TDropdownHeaderStatuses = Record<string, string>;
type TGridData = {
  periodsStartEnd: {
    "all-periods-count": {
      cals: TPeriodCount;
      hlvs: TPeriodCount;
      months: TPeriodCount;
      qtrs: TPeriodCount;
      seasons: TPeriodCount;
    };
  };
  headerStatuses: TDropdownHeaderStatuses;
  lastUpdated: string;
  allResolvedValues: Record<
    string,
    {
      pageId: string;
      lastShared: Array<{
        last_shared: {
          folio_user: { id: string; username: string };
          edited_at: string;
        };
      }>;
      resolvedValues: Record<
        string,
        {
          selector: string;
          values: Record<string, number | object | undefined>;
        }
      >;
      eodDate: string;
    }
  >;
};

type TPageSettingsUpdatedEvent = {
  type: "pageSettingsUpdated";
  data: { pages: TPage[] };
};

type TLogoutRequestedEvent = {
  type: "logoutRequested";
  data: { timestamp: string };
};

const monthNumLookup: Record<string, number> = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
} as const;

const monthCodeLookup = {
  JAN: "F",
  FEB: "G",
  MAR: "H",
  APR: "J",
  MAY: "K",
  JUN: "M",
  JUL: "N",
  AUG: "Q",
  SEP: "U",
  OCT: "V",
  NOV: "X",
  DEC: "Z",
} as const;

function monthCodeToDateString(code: string) {
  const year = code.slice(1);
  const monthCode = code[0];
  const month = Object.entries(monthCodeLookup).find(
    ([, code]) => code === monthCode,
  );
  if (!month) {
    return;
  }
  const monthNum = monthNumLookup[month[0]];
  return `20${year}-${String(monthNum).padStart(2, "0")}-01`;
}

function formatPeriod(periodTuple: [number, string]) {
  return periodTuple[1].replace(/\s/g, "");
}

function formatMonthString([, monthStr]: [number, string]) {
  const code = monthStr
    .slice(0, 3)
    .toUpperCase() as keyof typeof monthCodeLookup;
  const year = monthStr.slice(3);
  return `${monthCodeLookup[code]}${year}`;
}

type HandleWorkerProps = {
  setLoading: (loading: boolean) => void;
  setPages: (pages: TPage[]) => void;
  setPackages: (packages: TPackage[]) => void;
  setError: (error: string) => void;
  setLastUpdated: (lastUpdated: number) => void;
};

//TODO - not complete and could include enums

const codeIsQuarter = (code: string) => {
  return code.length === 4;
};

const codeToPeriod = (code: string): string => {
  if (codeIsQuarter(code)) {
    return `${code.slice(0, 2)} ${code.slice(2)}`;
  }

  const date = monthCodeToDateString(code);
  if (date !== undefined) {
    return date;
  }

  if (
    code.startsWith("Cal") ||
    code.startsWith("Win") ||
    code.startsWith("Sum")
  ) {
    return `${code.slice(0, 3)} ${code.slice(3)}`;
  }

  return "";
};

type TWorkerMessage = string | { type: string; data?: unknown };

function formatStatus(s: string | undefined) {
  const status = s ? s.charAt(0).toUpperCase() + s.slice(1) : "";
  if (status === "Hybrid_broadcast") {
    return "Hybrid";
  }
  if (status === "Eod") {
    return "EOD";
  }
  if (!status) {
    return "Listen";
  }
  return status;
}

function generateExcelRange(numOfCols: number, numRows: number) {
  const getColumnName = (index: number): string => {
    let columnName = "";
    while (index > 0) {
      const remainder = (index - 1) % 26;
      columnName = String.fromCharCode(65 + remainder) + columnName;
      index = Math.floor((index - 1) / 26);
    }
    return columnName;
  };

  const rangeStart = "A1";
  const rangeEnd = getColumnName(numOfCols) + numRows;

  return rangeStart + ":" + rangeEnd;
}

type TExcelData = (string | (string | undefined)[] | undefined)[];

function formatLastUpdateTime(date: Date): string {
  const pad = (num: number): string => num.toString().padStart(2, "0");

  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${hours}:${minutes}:${seconds}`;
}
const store = getDefaultStore();
function drawSheet(
  sheet: Excel.Worksheet,
  range: string,
  numOfRows: number,
  dataForExcel: TExcelData[],
) {
  sheet.getRange(range).values = Array.from({ length: numOfRows }, (_, i) =>
    dataForExcel.map((col, colIdx) => {
      if (colIdx === 0 && (i === 2 || i === 3)) {
        const lut = store.get(lastUpdatedAtom);
        if (i === 3) {
          if (lut) {
            return formatLastUpdateTime(lut);
          } else {
            return "";
          }
        }
        if (i === 2 && lut) {
          return "Last update:";
        }
      }
      const cellValue = col[i]?.toString();
      return cellValue ?? "";
    }),
  );
}

type TPageGridData = {
  currentPageName: string;
  range: string;
  numOfRows: number;
  dataForExcel: TExcelData[];
};

type TInitialData = {
  pages: TPage[];
  packages: TPackage[];
};

let running = false;
async function handleWorkerMsg(msg: TWorkerMessage, props: HandleWorkerProps) {
  const store = getDefaultStore();
  if (msg === "readyToStream") {
    store.set(readyAtom, true);
  }
  if (typeof msg === "string") {
    return;
  }
  if (msg === null) {
    return;
  }

  const { setLoading, setLastUpdated, setPages, setPackages } = props;
  const pages = store.get(pageStore.values);
  const currentPageIds = store.get(selectedPageIdsAtom);
  const products = store.get(productsAtom);

  switch (msg.type) {
    case "initialData": {
      const data = msg.data as TInitialData;
      console.log("initialData", { data });
      setPages(data.pages);
      setPackages(data.packages);
      return;
    }
    case "pageSettingsUpdated": {
      const message = msg as TPageSettingsUpdatedEvent;
      console.log("pageSettingsUpdated", { message });
      setPages(message.data.pages);
      return;
    }
    case "logoutRequested": {
      const message = msg as TLogoutRequestedEvent;
      logger.debug("logout message from worker", { message });
      localforage.setItem("logoutRequestedAt", message.data.timestamp);
      checkForLogout();
      return;
    }
  }

  const message = msg as unknown as TGridData;

  try {
    const lastUpdated = new Date(message.lastUpdated);
    setLastUpdated(lastUpdated.getTime());
  } catch (e) {
    logger.error("error parsing lastUpdated", { e, message });
  }

  const months =
    message.periodsStartEnd["all-periods-count"].months.map(formatMonthString);
  const qtrs =
    message.periodsStartEnd["all-periods-count"].qtrs.map(formatPeriod);
  const hlvs =
    message.periodsStartEnd["all-periods-count"].hlvs.map(formatPeriod);
  const cals =
    message.periodsStartEnd["all-periods-count"].cals.map(formatPeriod);
  const seasons =
    message.periodsStartEnd["all-periods-count"].seasons.map(formatPeriod);

  const gridDataPerPage = pages
    .filter((page) => currentPageIds.includes(page.id))
    .map((page) => {
      const currentPageId = page.id;
      const statuses = message.headerStatuses;
      const currentPage = pages.find((p) => p.id === currentPageId);
      const currentPageName =
        currentPage?.name || "No current page name data found";
      if (!currentPage) {
        logger.error("Page not found. Not rendering", {
          currentPage: currentPageName,
        });
        return;
      }
      const pageProducts = currentPage.pageProducts;

      const pageValues = message.allResolvedValues[currentPageId];
      if (!pageValues) {
        logger.error("No page values found for", {
          currentPageName,
          message,
          currentPageId,
          currentPage,
        });
        return;
      }

      const gridData = pageProducts
        .map((product) => {
          const gridId = product?.["grid-id"];
          if (!gridId) {
            return;
          }
          const productId = product["id"];
          const productDataObj = pageValues.resolvedValues?.[gridId];
          const selector = productDataObj?.selector;
          const productData = productDataObj?.values;
          const pInfo = products.find((p) => p.id === productId);
          if (!pInfo) {
            return;
          }
          const uomLabel = uomToLabel(pInfo.uom);
          return {
            ...productData,
            header: [
              formatStatus(statuses?.[productId]),
              pInfo.name,
              pInfo.description,
              productId,
              pInfo.source,
              selector,
              uomLabel,
            ],
          };
        })
        .filter((p) => p);

      const periods = [...months, ...qtrs, ...hlvs, ...cals, ...seasons];
      const productColumns = gridData.map((obj) => {
        if (!obj) return [];
        return [
          ...obj["header"],
          ...periods.map((period) => obj[period as keyof typeof obj]),
        ];
      });
      const periodColumn = [
        currentPageName,
        "",
        "",
        "",
        "",
        "",
        "Period",
        ...periods.map(codeToPeriod),
      ];
      const codeColumn = ["", "", "", "", "", "", "Code", ...periods];
      const dataForExcel = [periodColumn, codeColumn, ...productColumns];
      const numOfCols = dataForExcel.length;
      const numOfRows = codeColumn.length;
      const range = generateExcelRange(numOfCols, numOfRows);

      return {
        currentPageName,
        range,
        numOfRows,
        dataForExcel,
      } as TPageGridData;
    })
    .filter((pgd): pgd is TPageGridData => pgd !== undefined);

  if (!running && gridDataPerPage.length > 0) {
    Excel.run(
      {
        delayForCellEdit: true,
      },
      async (context) => {
        running = true;
        setLoading(false);
        const sheets = context.workbook.worksheets;
        sheets.load("items/name");

        await context.sync();

        gridDataPerPage.forEach((gridData) => {
          const { currentPageName, range, numOfRows, dataForExcel } = gridData;

          const sheetExists = sheets.items.some((sheet) =>
            compareStr(sheet.name, currentPageName),
          );
          if (sheetExists) {
            const sheet = sheets.getItem(currentPageName);
            drawSheet(sheet, range, numOfRows, dataForExcel);
          } else {
            const sheet = sheets.add(currentPageName);
            drawSheet(sheet, range, numOfRows, dataForExcel);
          }
        });

        const ctx = await context.sync();
        running = false;
        return ctx;
      },
    ).catch((error) => {
      logger.error("error in Excel.run updating sheets with data", error);
      running = false;
    });
  }
}

async function initializeWorker(props: HandleWorkerProps) {
  const worker = new Worker(new URL("./cljs/worker.js", import.meta.url));
  const promiseWorker = new PWBHost(worker);
  promiseWorker.register(async (msg) =>
    handleWorkerMsg(msg as unknown as TWorkerMessage, props).catch((error) => {
      logger.error("error in handleWorkerMsg", error);
    }),
  );
  return promiseWorker;
}

async function shutdownWorker(
  worker: PWBHost,
  setPaused: (paused: boolean) => void,
) {
  console.log("shutting down worker");
  await worker.postMessage({
    type: "excelStop",
  });

  console.log("worker paused. Setting paused to true");
  setPaused(true);
}

type TWorkerPayload = {
  type: string;
  data?: Record<string, unknown>;
  clientSpecific?: boolean;
};

type TSendMsgToWorker = (
  worker: PWBHost,
  payload: TWorkerPayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<any>;

type TWorkerFnProps = {
  worker: PWBHost;
  sendMsgToWorker: TSendMsgToWorker;
  setError: (e: string | undefined) => void;
  setCellsLoading: (b: boolean) => void;
  setPaused: (b: boolean) => void;
};

async function workerStartStreaming(
  props: TWorkerFnProps,
  currentPageIds: string[],
) {
  const { worker, sendMsgToWorker, setError, setCellsLoading, setPaused } =
    props;
  const payload = {
    type: "excelStart",
    data: { pageIds: currentPageIds },
  };

  const res = await sendMsgToWorker(worker, payload).catch((err) => {
    logger.error("error in workerStartStreaming", err);
    setError(err.toString());
    setCellsLoading(false);
  });
  if (res === "success") {
    setPaused(false);
  }
}

const syncExcelSheets = async (setSheets: (sheetNames: string[]) => void) => {
  try {
    return await Excel.run(async (context_1) => {
      const sheets = context_1.workbook.worksheets;
      sheets.load("items/name");
      await context_1.sync();

      const sheetNames_1 = sheets.items.map((sheet) => sheet.name);
      setSheets(sheetNames_1);

      return context_1.sync();
    });
  } catch (error) {
    logger.error("error in Excel.run when getting excel sheets", { error });
  }
};

function useWorker({
  worker,
  setWorker,
}: {
  worker: PWBHost | undefined;
  setWorker: (worker: PWBHost | undefined) => void;
}) {
  const [paused, setPaused] = useState(false);
  const [cellsLoading, setCellsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { user, token } = useAuth();
  const [lastUpdated, setLastUpdated] = useLastUpdated();
  const [connectionStatus, setConnectionStatus] = useState<
    "connected" | "disconnected" | "connecting"
  >("disconnected");
  const workerInitialisingRef = useRef<boolean>(false);
  const [pageIds] = useSelectedPageIds();
  const [, setPages] = usePages();
  const setPackages = useSetPackages();
  const hasuraName = useHasuraName();

  const sendMsgToWorker = useCallback(
    async (worker: PWBHost, payload: TWorkerPayload) => {
      if (!token || !user || !worker) {
        logger.warn("no token, user or worker", { token, user, worker });
        return;
      }

      const workerUser = { id: user.sub, jwt: token };
      const payloadWithUser = {
        ...payload,
        data: { ...payload.data, user: workerUser },
      };
      //logger.debug('sending msg to worker', { payloadWithUser });
      const res = await worker.postMessage(payloadWithUser);
      //logger.debug('worker response', { res });
      return res;
    },
    [token, user],
  );

  useEffect(() => {
    if (!lastUpdated || cellsLoading) {
      setConnectionStatus("connecting");
      return;
    }
    const lastUpdateDate = new Date(lastUpdated).getTime();
    if (lastUpdateDate > Date.now() - 5000) {
      setConnectionStatus("connected");
    } else {
      setConnectionStatus("connecting");
    }
    const timeout = setTimeout(() => {
      if (connectionStatus === "connecting") {
        setConnectionStatus("disconnected");
      } else {
        setConnectionStatus("connecting");
      }
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [lastUpdated, cellsLoading, connectionStatus]);

  useEffect(() => {
    if (worker || workerInitialisingRef.current) {
      return;
    }
    console.log("initializing worker", worker, workerInitialisingRef.current);
    workerInitialisingRef.current = true;
    initializeWorker({
      setLoading: setCellsLoading,
      setPages,
      setPackages,
      setError,
      setLastUpdated,
    }).then((worker) => {
      console.log("setting worker", worker);
      // this will set the workerRef in StreamingConsole
      setWorker(worker);
      setTimeout(() => {
        logger.debug("sending excelInit to worker", { user, hasuraName });
        if (worker) {
          sendMsgToWorker(worker, {
            type: "excelInit",
            data: { hasuraName },
          });
        } else {
          logger.warn("no worker", { user });
        }
      }, 100);
    });
    return () => {
      worker && shutdownWorker(worker, setPaused);
    };
  }, [
    hasuraName,
    user,
    worker,
    setWorker,
    setPages,
    setPackages,
    setLastUpdated,
    sendMsgToWorker,
  ]);

  const loadCells = async () => {
    if (!worker || !user?.sub) {
      return;
    }

    setCellsLoading(true);

    const workerProps = {
      worker,
      sendMsgToWorker,
      setError,
      setCellsLoading,
      setPaused,
    };

    await workerStartStreaming(workerProps, pageIds);
  };

  const stopCells = () => {
    if (!worker) {
      return;
    }

    shutdownWorker(worker, setPaused);
  };

  return {
    worker,
    loadCells,
    stopCells,
    cellsLoading,
    error,
    connectionStatus,
    paused,
  };
}

function PageSelector(props: { isStarted: boolean }) {
  const { isStarted } = props;

  const [allPages] = usePages();
  const [pageIds, setPages] = useSelectedPageIds();
  const [query, setQuery] = useState("");
  const selectedPages = allPages.filter((page) => pageIds.includes(page.id));

  const maxSelectedPages = 3;
  const maxPagesReached = selectedPages.length >= maxSelectedPages;
  const [, setSheets] = useExcelSheets();

  const handleSelect = (pages: TPage[]) => {
    setPages(pages);
    syncExcelSheets(setSheets);
  };

  return (
    <Combobox
      value={selectedPages}
      disabled={isStarted}
      onChange={handleSelect}
      multiple
    >
      {({ open }) => (
        <div className={clsx("relative w-full", { "opacity-50": isStarted })}>
          <Combobox.Label className="block text-sm font-medium self-start mb-2">
            Select up to 3 Pages to stream:{" "}
          </Combobox.Label>
          <span className="inline-block w-full rounded-md shadow-sm">
            <div className="relative w-full  rounded-md border border-gray-300 bg-white  pl-2 pr-10 transition duration-150 ease-in-out focus-within:border-blue-700 focus-within:outline-none focus-within:ring-1 focus-within:ring-blue-700 sm:text-sm sm:leading-5">
              {selectedPages.map((page) => (
                <span
                  key={page.id}
                  onClick={() => {
                    if (isStarted) return;
                    const newPages = selectedPages.filter(
                      (p) => page.id !== p.id,
                    );
                    setPages(newPages);
                  }}
                  className={clsx(
                    "flex items-center justify-between my-2 rounded bg-blue-50 px-2 py-0.5",
                    isStarted ? "cursor-not-allowed" : "cursor-pointer",
                  )}
                >
                  <span className="truncate">{page.name}</span>
                  <svg
                    className="h-4 w-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              ))}
              <Combobox.Input
                onChange={(event) => setQuery(event.target.value)}
                onFocus={(e) => {
                  if (
                    e.relatedTarget?.id?.includes("headlessui-combobox-button")
                  )
                    return;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !open && e.target.nextSibling?.click();
                }}
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                placeholder="Search..."
              />
              <Combobox.Button
                aria-disabled={isStarted}
                className="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <svg
                  className="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Combobox.Button>
            </div>
          </span>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {allPages
                .filter((page) =>
                  page.name.toLowerCase().includes(query.toLowerCase()),
                )
                .map((page) => (
                  <Combobox.Option
                    key={page.id}
                    value={page}
                    disabled={maxPagesReached && !selectedPages.includes(page)}
                    className={({ active }) => {
                      return clsx(
                        "relative  select-none py-2 pl-3 pr-9 focus:outline-none",
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                      );
                    }}
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={clsx(
                            "block truncate",
                            selected ? "font-semibold" : "font-normal",
                          )}
                        >
                          {page.name}
                        </span>
                        {selected && (
                          <span
                            className={clsx(
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                              active ? "text-white" : "text-indigo-600",
                            )}
                          >
                            <svg
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </Transition>
        </div>
      )}
    </Combobox>
  );
}

export function LoadingDelayedMessage() {
  const [loadingIsDelayed, setLoadingIsDelayed] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoadingIsDelayed(true);
    }, 15000);
  }, []);

  if (!loadingIsDelayed) {
    return null;
  }

  return (
    <div className="w-4/5 mt-4 text-xs text-red-500">
      <p>
        This is taking longer than expected... Try logging out and in again.
      </p>
      <p className="mt-2">If the issue persists, please contact Support.</p>
      <br />
      <LogoutButton />
    </div>
  );
}

function StreamingConsole() {
  const { user } = useAuth();
  const workerRef = useRef<PWBHost | undefined>(undefined);
  const worker = workerRef.current;
  const { loadCells, stopCells, cellsLoading, error, connectionStatus } =
    useWorker({
      worker,
      setWorker: (worker) => (workerRef.current = worker),
    });
  const [isStarted, setIsStarted] = useState(false);
  const [selectedPages] = useSelectedPages();
  const [pages] = usePages();
  const [sheetNames] = useExcelSheets();
  const { online } = useNetworkState();
  const connection = online ? connectionStatus : "offline";
  const lastUpdated = useAtomValue(lastUpdatedAtom);

  const hasSelectedPages = selectedPages.length > 0;
  const isLoadingPages = !pages || pages.length === 0;

  const willOverwriteSheet = sheetNames.some((sheet) =>
    selectedPages.some((page) => compareStr(page.name, sheet)),
  );
  const ready = useReady();
  return (
    <>
      {worker ? (
        <>
          {isLoadingPages ? (
            <>
              <p className="text-base">Loading pages</p>
              <LoadingDelayedMessage />
            </>
          ) : (
            <>
              <PageSelector isStarted={isStarted} />

              <div className="w-full">
                {isStarted && !cellsLoading ? (
                  <Button
                    primary
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    <span>Stop</span>
                  </Button>
                ) : (
                  <>
                    <Button
                      primary
                      onClick={() => {
                        loadCells();
                        setIsStarted(true);
                      }}
                      disabled={
                        cellsLoading || !hasSelectedPages || !online || !ready
                      }
                    >
                      {cellsLoading ?? !ready ? (
                        <span>Loading...</span>
                      ) : (
                        <span>Stream</span>
                      )}
                    </Button>
                  </>
                )}
              </div>
            </>
          )}

          {willOverwriteSheet && !isStarted && (
            <span className="text-red-500">
              Warning: clicking 'Stream' will overwrite content in any existing
              worksheets with the same name as the Page(s) that you have
              selected
            </span>
          )}

          <>
            <button
              type="button"
              onClick={() => {
                if (connection === "disconnected") {
                  stopCells();
                  setTimeout(() => {
                    loadCells();
                    setIsStarted(true);
                  }, 100);
                }
              }}
              className={clsx(
                "inline-flex capitalize items-center text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full",
                !isStarted
                  ? "bg-gray-100 text-gray-800"
                  : connection === "connected"
                    ? "bg-green-100 text-green-800"
                    : connection === "connecting"
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-red-100 text-red-800",
                "hover:brightness-90 transition duration-150 ease-in-out",
              )}
            >
              <span
                className={clsx(
                  "w-2 h-2 mr-1 rounded-full",
                  !isStarted
                    ? "bg-gray-500"
                    : connection === "connected"
                      ? "bg-green-500 animate-pulse"
                      : connection === "connecting"
                        ? "bg-yellow-500 animate-ping"
                        : "bg-red-500",
                )}
              />
              {isStarted ? connection : "Stopped"}
            </button>
            {user?.email ? (
              <div className="flex">
                <span className="font-bold whitespace-nowrap">
                  Logged in as:&nbsp;
                </span>
                <span>{user?.email || "-"}</span>
              </div>
            ) : (
              <p>Authenticating...</p>
            )}
            <div className="hidden h300:block absolute bottom-5">
              <LogoutButton />
            </div>
          </>
        </>
      ) : (
        <>
          <p>Loading...</p>
          <LoadingDelayedMessage />
        </>
      )}

      {error && <p className="text-xs text-red-500">{error}</p>}
    </>
  );
}

async function checkForLogout() {
  const initTimeStr = await localforage.getItem<number>("loginTime");
  if (!initTimeStr) {
    logger.error("No login time found, this shouldnt happen");
    // to handle migration edge cases, will be overwritten the next day they login
    localforage.setItem("loginTime", 1);
    return;
  }
  const initTime = new Date(initTimeStr);
  const logoutTimeString =
    await localforage.getItem<string>("logoutRequestedAt");
  const logoutTime = logoutTimeString ? new Date(logoutTimeString) : undefined;
  if (!!logoutTime && !!initTime && logoutTime > initTime) {
    logger.debug("logoutRequested", { initTime, logoutTime });
    logoutFn();
  }
  return;
}

function useCheckForLogout() {
  useEffect(() => {
    const timer = setInterval(() => {
      checkForLogout();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);
}

function App() {
  const hasuraName = useHasuraName();
  useHotkeys(["ctrl+shift+l", "meta+shift+l"], logoutFn);
  useCheckForLogout();

  return (
    <div className="w-full h-full border-solid border-gray-400 border p-10">
      <>
        <div className="h-full flex flex-col items-start gap-4 text-sm">
          {hasuraName ? (
            <StreamingConsole />
          ) : (
            <>
              <p>Environment name not set</p>
              <LogoutButton />
            </>
          )}
        </div>
      </>
    </div>
  );
}

export default App;
