import clsx from 'clsx';
import { logoutFn } from './store';

export function Button(props: {
  onClick: () => void;
  primary?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}) {
  const { onClick, disabled, primary, children } = props;
  return (
    <button
      className={clsx(
        'px-4 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md',
        primary && 'bg-artis-primary text-gray-900',
        disabled
          ? 'bg-gray-300 cursor-not-allowed'
          : 'hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export function LogoutButton() {
  return (
    <Button primary onClick={logoutFn}>
      Disconnect
    </Button>
  );
}
