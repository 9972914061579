import { ErrorBoundary } from 'react-error-boundary';
import App, { LoadingDelayedMessage } from './App';
import { useEffect, useState } from 'react';
import logger from './logger';
import { decryptData } from './utils';
import usePartySocket from 'partysocket/react';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { HASURA_NAMES } from './constants';
import { Button, LogoutButton } from './Buttons';
import {
  configStore,
  logoutFn,
  pageStore,
  useDecodedToken,
  useToken,
  useUser,
} from './store';
import localforage from 'localforage';
import { useSetAtom } from 'jotai';

const logError = (error: Error, info: React.ErrorInfo) => {
  logger.error(error.message, { error, errorInfo: info });
};

function ErrorFallback() {
  const clearPages = useSetAtom(pageStore.clear);
  const clearConfig = useSetAtom(configStore.clear);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-bold text-gray-900">
          Something went wrong
        </h1>
        <p className="text-gray-600">
          An error occurred while trying to render the application.
        </p>
        <button
          className="px-4 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          onClick={() => {
            clearPages();
            clearConfig();
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }}
        >
          Try Again
        </button>
      </div>
    </div>
  );
}

async function generatePasskey(): Promise<string> {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  const arr = Array.from(array);
  const str = String.fromCharCode.apply(null, arr);
  return btoa(str);
}

let passkey = '';

export async function handleOpenFolio(code: string) {
  localforage.setItem('loginTime', Date.now());
  const baseUrl = window.location.protocol + '//' + window.location.host;
  const prodUrl = 'https://folio.artis.works';
  const isProd = window.location.host === 'excel.artis.works';
  const folioUrl = (isProd ? prodUrl : baseUrl) + '/excel/login.html';
  const randomstring = await generatePasskey();
  const k = encodeURIComponent(randomstring);
  passkey = randomstring;
  const url = `${folioUrl}?passkey=${k}&pcode=${code}`;
  logger.debug('handleOpenFolio', { url });
  try {
    Office.context.ui.openBrowserWindow(url);
  } catch (error) {
    logger.debug('handleOpenFolio error, falling back to window.open', {
      error,
    });
    window.open(url, 'folioLogin');
  }
}

type Exchange = {
  code: number;
  source: number;
};

type AuthorizedPackage = {
  id: number;
  name: string;
  perm: string;
  sourceBySource: {
    exchange: Exchange;
  };
};

type HasuraClaims = {
  'authorized-packages': AuthorizedPackage[];
  'x-hasura-allowed-roles': string[];
  'x-hasura-default-role': string;
  'x-hasura-org-id': string;
  'x-hasura-user-email': string;
  'x-hasura-user-id': string;
};

export type JWT = {
  'https://hasura.io/jwt/claims': HasuraClaims;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  org_id: keyof typeof HASURA_NAMES;
};

export function userHasAddinRole(decoded: JWT) {
  logger.debug('checking user roles', { decoded });
  const roles =
    decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
  logger.debug('user roles', { roles });
  return roles.includes('excel-add-in');
}

export function checkToken({ decoded }: { decoded?: JWT }) {
  if (decoded === undefined) {
    return;
  }

  const msToExpiry = decoded.exp ? decoded.exp * 1000 - Date.now() : 0;
  const isExpired = msToExpiry <= 0;
  if (isExpired) {
    logger.info('Token has expired');
    logoutFn();
  }

  const userData = {
    sub: decoded.sub,
    email: decoded['https://hasura.io/jwt/claims']['x-hasura-user-email'],
  };

  const hasRole = userHasAddinRole(decoded);

  if (!hasRole) {
    logger.warn('User does not have addin role', { decoded });
    return {
      error:
        "You don't have access to the Artis for Excel service - please contact your Account Executive",
    };
  }

  console.log('token is valid', { userData, msToExpiry });

  return {
    user: userData,
    tokenMsToExpiry: msToExpiry,
  };
}

function usePartyToken({
  room,
  setError,
}: {
  room: string;
  setError: (e: string | null) => void;
}) {
  const [token, setToken] = useToken();
  const [errorCount, setErrorCount] = useState(0);
  const decoded = useDecodedToken();

  useEffect(() => {
    if (decoded?.error) {
      setError(decoded.error);
    }
  }, [decoded, setError]);

  const visibleErrorMessage = 'Error Logging In';
  usePartySocket({
    host: 'astp.armincerf.partykit.dev',
    room,
    onOpen: () => {
      console.log('connected');
      setError(null);
      setErrorCount(0);
    },
    onMessage: async (e) => {
      console.log('message', e);
      if (e.data === 'ack') {
        return;
      }
      if (e.data === 'logout') {
        logoutFn();
        return;
      }

      decryptData(e.data, passkey)
        .then(async (decrypted) => {
          const decryptedData = JSON.parse(decrypted) as { jwt: string };
          setToken(decryptedData.jwt);
          setError(null);
        })
        .catch((error) => {
          logger.debug('error decrypting token', { error });
          if (!decoded) {
            setError(visibleErrorMessage);
          }
        });
    },
    onClose: () => {
      console.log('disconnected');
    },
    onError: (e) => {
      console.log('error', e);
      setErrorCount((c) => c + 1);
      if (errorCount >= 5) {
        logoutFn();
      }
      if (!decoded) {
        setError(visibleErrorMessage);
      }
    },
  });

  return {
    token,
  };
}

export function AuthWrapper({ code }: { code: string }) {
  const [loading, setLoading] = useState(false);
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex flex-col items-center justify-center">
        <p className="text-gray-600">Please connect to Artis to continue</p>
        <Button
          disabled={loading}
          onClick={() => {
            setLoading(true);
            handleOpenFolio(code);
            setTimeout(() => {
              window.focus();
            }, 1000);
          }}
        >
          {loading ? 'Authenticating...' : 'Connect'}
        </Button>
        {loading && <LoadingDelayedMessage />}
      </div>
    </div>
  );
}

export function useAuth() {
  const { expiresInMs, id, email, rawToken, error } = useUser();

  useEffect(() => {
    if (error) return;
    const timerId = setTimeout(() => {
      logger.info('Auth token expired. Logging out');
      logoutFn();
    }, expiresInMs);

    return () => {
      console.log('clearing the timeout', { expiresInMs });
      clearTimeout(timerId);
    };
  }, [expiresInMs, error]);

  return {
    user: {
      sub: id,
      email,
    },
    error,
    token: rawToken,
  };
}

export function AuthApp() {
  const code = getBrowserFingerprint();
  const [error, setError] = useState<string | null>(null);
  const { token } = usePartyToken({ room: code, setError });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      {error ? (
        <div className="relative flex flex-col items-center justify-center h-screen">
          <div className="flex flex-col items-center justify-center">
            <span className="text-sm m-4 text-center text-red-600">
              {error}
            </span>
            <div className="absolute bottom-0 left-0 m-4">
              <LogoutButton />
            </div>
          </div>
        </div>
      ) : token ? (
        <App />
      ) : (
        <AuthWrapper code={code} />
      )}
    </ErrorBoundary>
  );
}
