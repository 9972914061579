import { Logtail } from '@logtail/browser';
import { Context } from '@logtail/types';
import { JWT } from './Auth';

const sendToLogtail =
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'deploy-preview' ||
  import.meta.env.MODE === 'branch';

console.log('Sending to Logtail?: ', sendToLogtail);
const logtail = new Logtail('iDGwm7hoTFKnXMzGWcJd5NtM');

let jwt: JWT | null = null;

export function setLoggerJWT(decodedJWT: JWT) {
  jwt = decodedJWT;
}

function sanitize(object: Context) {
  const clonedObject = { ...object };
  for (const key in clonedObject) {
    if (clonedObject[key] && typeof clonedObject[key] === 'object') {
      // Dig deeper if the property is an object
      clonedObject[key] = sanitize(clonedObject[key]);
    }
    if (key === 'jwt') {
      // Delete the key
      delete clonedObject[key];
    }
  }
  return clonedObject;
}

function log(
  level: 'debug' | 'info' | 'warn' | 'error',
  message: string,
  body?: Context,
) {
  const context = {
    info: body,
    user: {
      id:
        jwt?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id'] ??
        'unknown',
      email: jwt?.['https://hasura.io/jwt/claims']?.['x-hasura-user-email'],
    },
    environment: import.meta.env.MODE ?? 'unknown',
  };
  console[level](message, context.info);
  if (sendToLogtail) {
    logtail[level](message, sanitize(context));
    logtail.flush();
  }
}

export const debug = (message: string, body?: Context) => {
  log('debug', message, body);
};

export const info = (message: string, body?: Context) => {
  log('info', message, body);
};

export const warn = (message: string, body?: Context) => {
  log('warn', message, body);
};

export const error = (message: string, body?: Context) => {
  log('error', message, body);
};

export default { debug, info, warn, error };
