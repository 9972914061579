export function getUser() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const user = params.get('user');
  return user;
}

/*
    Managing the dialogs.
*/

type LoginMessageSuccess = {
  message: string;
  origin: string | undefined;
};
type LoginMessageError = {
  error: number;
};
type LoginMessage = LoginMessageSuccess | LoginMessageError;

type TAppState = {
  authStatus: 'notLoggedIn' | 'loginInProcess' | 'loggedIn';
  headerMessage?: 'Welcome' | 'Get Data';
};

let logoutDialog: Office.Dialog;
const dialogLogoutUrl: string =
  location.protocol +
  '//' +
  location.hostname +
  (location.port ? ':' + location.port : '') +
  '/logout/logout.html';

export const logoutFromO365 = async (
  setState: (appState: TAppState) => void,
  displayError: (x: string) => void,
) => {
  Office.context.ui.displayDialogAsync(
    dialogLogoutUrl,
    { height: 40, width: 30 },
    (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        displayError(`${result.error.code} ${result.error.message}`);
      } else {
        logoutDialog = result.value;
        logoutDialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          processLogoutMessage,
        );
        logoutDialog.addEventHandler(
          Office.EventType.DialogEventReceived,
          processLogoutDialogEvent,
        );
      }
    },
  );

  const processLogoutMessage = () => {
    logoutDialog.close();
    setState({ authStatus: 'notLoggedIn', headerMessage: 'Welcome' });
  };

  const processLogoutDialogEvent = (arg: LoginMessage) => {
    processDialogEvent(arg, setState, displayError);
  };
};

const processDialogEvent = (
  arg: LoginMessage & { error?: number },
  setState: (appState: TAppState) => void,
  displayError: (x: string) => void,
) => {
  switch (arg.error) {
    case 12002:
      displayError(
        'The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.',
      );
      break;
    case 12003:
      displayError(
        'The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.',
      );
      break;
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      setState({ authStatus: 'notLoggedIn', headerMessage: 'Welcome' });
      break;
    default:
      displayError('Unknown error in dialog box.');
      break;
  }
};

const base64_to_buf = (b64: string): Uint8Array =>
  Uint8Array.from(atob(b64), (c: string) => c.charCodeAt(0) as number);

const enc = new TextEncoder();
const dec = new TextDecoder();

const getPasswordKey = async (password: string): Promise<CryptoKey> =>
  window.crypto.subtle.importKey('raw', enc.encode(password), 'PBKDF2', false, [
    'deriveKey',
  ]);

const deriveKey = (
  passwordKey: CryptoKey,
  salt: Uint8Array,
  keyUsage: KeyUsage[],
): PromiseLike<CryptoKey> =>
  window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: salt,
      iterations: 250000,
      hash: 'SHA-256',
    },
    passwordKey,
    { name: 'AES-GCM', length: 256 },
    false,
    keyUsage,
  );

export async function decryptData(
  encryptedData: string,
  password: string,
): Promise<string> {
  try {
    console.log('decrypting data', { password });
    const encryptedDataBuff = base64_to_buf(encryptedData);
    const salt = encryptedDataBuff.slice(0, 16);
    const iv = encryptedDataBuff.slice(16, 16 + 12);
    const data = encryptedDataBuff.slice(16 + 12);
    const passwordKey = await getPasswordKey(password);
    const aesKey = await deriveKey(passwordKey, salt, ['decrypt']);
    const decryptedContent = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      aesKey,
      data.buffer,
    );
    return dec.decode(new Uint8Array(decryptedContent));
  } catch (e) {
    console.log(`Error - ${e}`);
    throw e;
  }
}
